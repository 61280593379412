.App {
	min-height: 100vh;
	text-align: center;
	background-color: #282c34;
	color: white;
	padding: 0.5rem;
}

.Link {
	color: #61dafb;
	margin: 0.25rem;
	cursor: pointer;
	text-decoration: none;
}

.Link:hover {
	text-decoration: underline;
}

/* background-color: #282c34; */
/* color: #61dafb; */
